import { useSelector } from 'react-redux';

export function useFocusErrorList({
  docName,
  rootKey,
  formName
}: {
  docName: string | undefined,
  rootKey: string | undefined,
  formName?: string
}): string[] {
  return useSelector((state: any) => {
    if (formName) {
      return state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? '']?.[formName] || [] as string[];
    }

    return Object.values(state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? ''] || {}).flat() as string[];
  });
}
